import styled from '@emotion/styled'
import Grid from '@mui/material/Grid2'

export const GridPageContainer = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  max-width: 80%;
  margin: 0 auto;
`
export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 6px;
  max-width: 500px;
`

export const GridFormContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`
