import styled from '@emotion/styled'
import { headerHeight } from '../../theme'

export const Wrapper = styled.div`
  #home-page-wrapper {
    min-height: unset;
    height: calc(100vh - ${headerHeight});
  }
  p {
    margin-top: 0;
    margin-bottom: 0.9rem;
  }
`
