import React, { useState } from 'react'
import { IconButton, Menu, MenuItem, Typography, BoxProps } from '@mui/material'
import { AccountCircle } from '@mui/icons-material'
import { useAtom } from 'jotai'
import { userAtom } from '../../../stores/userAtom'
import { UsernameContainer } from './UserSection.styled'
import { useAuth } from '../../../hooks/useAuth'
import withCapabilities from '../../../hoc/withCapabilities'
import { useTranslation } from 'react-i18next'

const UserSection: React.FC<BoxProps> = (props) => {
  const [user] = useAtom(userAtom)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { t } = useTranslation()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const { logout } = useAuth()

  return (
    <UsernameContainer {...props}>
      <IconButton onClick={handleMenu} color="secondary">
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={logout} key="logout">
          <Typography variant="body1">{t('header.logout')}</Typography>
        </MenuItem>
      </Menu>
      <Typography variant="body1">{user?.name}</Typography>
    </UsernameContainer>
  )
}

export default withCapabilities(UserSection)
