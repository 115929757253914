import React from 'react'
import { Box, BoxProps } from '@mui/material'
import { LogoContainer } from './HeaderLogo.styled'
import { useNavigate } from 'react-router-dom'
import withCapabilities from '../../../hoc/withCapabilities'
import logo from '../../../assets/images/crew-logo.png'

const HeaderLogo: React.FC<BoxProps> = (props) => {
  const navigate = useNavigate()

  return (
    <LogoContainer
      {...props}
      onClick={() => {
        navigate('/')
      }}
    >
      <Box
        component="img"
        alt="Logo"
        src={logo}
        sx={{
          width: 100,
        }}
      />
    </LogoContainer>
  )
}

export default withCapabilities(HeaderLogo)
